export const dspservicesConstants = {
    FETCH_REQUEST: 'DSP_SERVICES_FETCH_REQUEST',
    FETCH_SUCCESS: 'DSP_SERVICES_FETCH_SUCCESS',
    FETCH_FAILURE: 'DSP_SERVICES_FETCH_FAILURE',
    
    CLOSE_DETAILS: 'CLOSE_DETAILS',
    DETAILS_REQUEST: 'DETAILS_REQUEST',
    DETAILS_SUCCESS: 'DETAILS_SUCCESS',
    DETAILS_FAILURE: 'DETAILS_FAILURE',
    
    SEARCH: 'SEARCH',
    SORT: 'SORT',
    CLEAR: 'CLEAR',

    SET_DETAILS: 'SET_DETAILS',

    SET_DSP: 'SET_DSP',
    SET_DATA: 'DSP_SERVICES_SET_DATA',
    FILTER_SERVICES: 'DSP_SERVICES_FILTER_SERVICES',
    FILTER_STATUS: 'DSP_SERVICES_FILTER_STATUS',
};
