export const packagelistConstants = {
    FETCH_REQUEST: 'PACKAGE_LIST_FETCH_REQUEST',
    FETCH_SUCCESS: 'PACKAGE_LIST_FETCH_SUCCESS',
    FETCH_FAILURE: 'PACKAGE_LIST_FETCH_FAILURE',
    
    CLOSE_DETAILS: 'PACKAGE_LIST_CLOSE_DETAILS',
    DETAILS_REQUEST: 'PACKAGE_LIST_DETAILS_REQUEST',
    DETAILS_SUCCESS: 'PACKAGE_LIST_DETAILS_SUCCESS',
    DETAILS_FAILURE: 'PACKAGE_LIST_DETAILS_FAILURE',
    
    SEARCH: 'PACKAGE_LIST_SEARCH',
    SORT: 'PACKAGE_LIST_SORT',
    CLEAR: 'PACKAGE_LIST_CLEAR',

    SET_DETAILS: 'PACKAGE_LIST_SET_DETAILS',

    SET_DSP: 'PACKAGE_LIST_SET_DSP',
    SET_DATA: 'PACKAGE_LIST_SET_DATA',
    FILTER_SERVICES: 'PACKAGE_LIST_FILTER_SERVICES',
    FILTER_STATUS: 'PACKAGE_LIST_FILTER_STATUS',
};
