export const dspConstants = {
    SET_DATA: 'DSP_SET_DATA',
    ADD_DATA: 'DSP_ADD_DATA',

    SORT: 'DSP_SORT',
    SET_TOTAL_USER: 'DSP_SET_TOTAL_USER',
    SET_TOTAL_STAKED: 'DSP_SET_TOTAL_STAKED',
    SET_DETAILS: 'DSP_SET_DETAILS',
    
    FETCH_REQUEST: 'DSP_FETCH_REQUEST',
    FETCH_SUCCESS: 'DSP_FETCH_SUCCESS',
    FETCH_FAILURE: 'DSP_FETCH_FAILURE',

    FETCH_STAKES_REQUEST: 'DSP_FETCH_STAKES_REQUEST',
    FETCH_STAKES_SUCCESS: 'DSP_FETCH_STAKES_SUCCESS',
    FETCH_STAKES_FAILURE: 'DSP_FETCH_STAKES_FAILURE',
    
};
